import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { requestAppointment } from 'actions/appointments';
import { loadUserAppointments } from 'actions/user';
import useSignupSteps from 'hooks/useSignupSteps';
import AddNewCard from 'organisms/AddNewCard';
import { DefaultCard } from 'organisms/DefaultCard';
import PaymentMethodModal from 'organisms/PaymentMethodModal';
import ProgressBar from 'organisms/ProgressBar';
import VaccinationExpiredModal from 'organisms/VaccinationExpiredModal';
import Warning24HoursModal from 'organisms/Warning24HoursModal';
import { displayPromoBanner, promotionCode } from 'store/reducers/invoice';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';
import { isNotEmptyArray, isWithin24Hours } from 'util';
import { trackAddPayment, trackPurchase } from 'util/tracking/events';

export default function PaymentInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const [isPaymentMethodModalOpen, openPaymentMethodModal] = useState(false);
  const [isWarningModalOpen, openWarningModal] = useState(false);
  const [isVaccinationExpiredModalOpen, setIsVaccinationExpiredModalOpen] =
    useState(false);
  const { dateStart } = useSelector((state) => state.requestAppointmentForm);
  const { cards } = useSelector((state) => state.user.paymentMethod);
  const isSignup = location.pathname.includes('/signup/');
  const [numberOfSteps, , deleteExtraSignupStep] = useSignupSteps();
  const { data } = useSelector((state) => state.user);
  const waivers = data?.waivers;
  const hasSignedWaiver = isNotEmptyArray(waivers) && !!waivers[0].dateCreated;
  const { publicSettings, uuid: luid } = useSelector((state) => state.location);
  const [isNewCustomer, setIsNewCustomer] = useState(true);

  const requireAnyCustomerToAddCard =
    publicSettings?.ccRequiredForBookings === true;
  const canOldCustomerContinueWithoutCard =
    !isNewCustomer && publicSettings?.ccRequiredForNewClientsBookings === true;
  const canContinueWithoutCard =
    !requireAnyCustomerToAddCard || canOldCustomerContinueWithoutCard;

  useEffect(() => {
    dispatch(trackAddPayment());

    dispatch(loadUserAppointments({ luid })).then(({ success, data }) => {
      if (success) {
        const { active, cancelled, past } = data.data;

        const hasActiveApptWithProvider = isNotEmptyArray(active);
        const hasCancelledApptWithProvider = isNotEmptyArray(cancelled);
        const hasPastApptWithProvider = isNotEmptyArray(past);

        if (
          hasActiveApptWithProvider ||
          hasCancelledApptWithProvider ||
          hasPastApptWithProvider
        ) {
          setIsNewCustomer(false);
        }
      }
    });

    return () => sessionStorage.removeItem('requestAppointmentForm');
  }, []);

  const onSubmit = () => {
    if (isWithin24Hours(dateStart)) {
      openWarningModal(true);
    } else makeAppointmentRequest();
  };

  const makeAppointmentRequest = () => {
    dispatch(requestOngoing());

    dispatch(requestAppointment())
      .then((res) => {
        if (res.success) {
          dispatch(trackPurchase());
          sessionStorage.removeItem('promoCode');
          dispatch(displayPromoBanner(false));
          dispatch(promotionCode(null));
          deleteExtraSignupStep();

          const confirmationLink = isSignup
            ? '/signup/booking-confirmation'
            : '/booking-confirmation';
          navigate(confirmationLink);
        } else if (res?.data?.[0]?.field === 'animal_vaccinations') {
          setIsVaccinationExpiredModalOpen(true);
        }
      })
      .then(() => dispatch(requestFulfilled()))
      .catch(() => dispatch(requestFulfilled()));
  };

  const onChangePaymentMethod = () => {
    openPaymentMethodModal(true);
  };

  return (
    <div className="payment-info pb-100-mobile">
      <div className="flex-column payment-info-header">
        {!isMobile && (
          <>
            <h1 className="mb-0">Complete booking</h1>
            <p className="mb-25">
              Your card will not be charged for the service until after your
              appointment.
            </p>
          </>
        )}
        {!isMobile && (
          <ProgressBar
            maxSteps={numberOfSteps}
            currentStep={numberOfSteps}
            className={classNames('mb-32', isMobile && 'mt-30')}
          />
        )}
      </div>
      <div className="payment-info-form">
        {cards.length ? (
          <DefaultCard
            onChangePaymentMethod={onChangePaymentMethod}
            onSubmit={onSubmit}
            primaryButtonText="Complete booking"
            hideDiscount
            hasSignedWaiver={hasSignedWaiver}
          />
        ) : (
          <AddNewCard
            onSubmit={onSubmit}
            primaryButtonText="Complete booking"
            hideDiscount
            hasSignedWaiver={hasSignedWaiver}
            canContinueWithoutCard={canContinueWithoutCard}
          />
        )}
      </div>
      <PaymentMethodModal
        isOpen={isPaymentMethodModalOpen}
        openModal={openPaymentMethodModal}
      />
      <Warning24HoursModal
        isOpen={isWarningModalOpen}
        openModal={openWarningModal}
        onConfirm={makeAppointmentRequest}
      />
      <VaccinationExpiredModal
        isOpen={isVaccinationExpiredModalOpen}
        setIsOpen={setIsVaccinationExpiredModalOpen}
      />
    </div>
  );
}
