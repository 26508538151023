import './Drawer.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import useWindowDimensions from 'helpers/useWindowDimensions';

import Icon from 'atoms/icon';
import AppointmentDrawer from 'organisms/DrawerContent/Appointment';
import UpdateVaccinationsDrawer from 'organisms/DrawerContent/UpdateVaccinations';
import { closeDrawer } from 'store/reducers/ui';

export default function DrawerComponent() {
  const dispatch = useDispatch();
  const { isOpen, type, position } = useSelector((state) => state.ui.drawer);
  const { width } = useWindowDimensions();
  const isMobile = width < 600;

  const COMPONENT_TYPES = {
    appointment: AppointmentDrawer,
    'update-vaccinations': UpdateVaccinationsDrawer,
  };

  const onCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const Element = COMPONENT_TYPES[type];

  return (
    <Drawer anchor={position || 'right'} open={isOpen} onClose={onCloseDrawer}>
      {isMobile && (
        <Icon
          name="close"
          onClick={() => dispatch(closeDrawer())}
          style={{ fill: 'black' }}
          className="mb-20 close-drawer-icon"
        />
      )}
      {Element ? <Element /> : null}
    </Drawer>
  );
}
