import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import toast from 'atoms/toast';
import { displayErrorMessage } from 'util';

export const headers = {
  'X-PawCare-API-Key': process.env.REACT_APP_API_KEY,
};

export const fetchData = ({
  endpoint,
  httpMethod = 'get',
  actions,
  body,
  isMedia,
  showAPIerror,
  displayErrorToast = true,
  bypassImpersonation,
  token: passedToken,
  headers: extraHeaders,
}) => {
  if (!endpoint && actions) return;
  const basePath = process.env.REACT_APP_API_DOMAIN;

  return (dispatch, getState) => {
    const [request, success, failure] = actions;
    const { token } = getState().user;
    let fetchHeaders = headers;

    // sessionStorage and passedToken are used on Survey flow
    let requestToken =
      token ||
      sessionStorage.getItem('token') ||
      localStorage.getItem('token') ||
      passedToken;

    if (bypassImpersonation) {
      requestToken = token || localStorage.getItem('token');
    }
    if (requestToken) {
      fetchHeaders = {
        ...headers,
        Authorization: `Bearer:${requestToken}`,
      };
    }

    if (extraHeaders) {
      fetchHeaders = Object.assign(headers, extraHeaders);
    }

    const postBody = isMedia ? body : decamelizeKeys(body);

    if (request) dispatch(request());
    return axiosRequest({
      fetchHeaders,
      basePath,
      endpoint,
      body: postBody,
      httpMethod,
    })
      .then(({ data }) => {
        const camelizedData = camelizeKeys(data);
        if (success) dispatch(success(camelizedData));
        return { success: true, data: camelizedData };
      })
      .catch((error) => {
        const errorData = error.response?.data?.detail;
        if (failure) dispatch(failure({ error: errorData }));
        let errorMessage = null;
        if (showAPIerror) {
          if (process.env.REACT_APP_MODE === 'development') {
            console.log(errorData);
          }
          errorMessage =
            typeof errorData === 'string' ? errorData : errorData[0].message;
        }
        if (displayErrorToast && !(errorData?.[0]?.code === 204))
          toast({ text: displayErrorMessage({ errorData, errorMessage }) });
        return {
          success: false,
          data: errorData,
          status: error.response?.status,
        };
      });
  };
};

const axiosRequest = ({
  fetchHeaders,
  basePath,
  endpoint,
  body,
  httpMethod,
}) => {
  if (httpMethod === 'get')
    return axios.get(`${basePath}/${endpoint}`, { headers: fetchHeaders });
  if (httpMethod === 'post')
    return axios.post(`${basePath}/${endpoint}`, body, {
      headers: fetchHeaders,
    });
  if (httpMethod === 'patch')
    return axios.patch(`${basePath}/${endpoint}`, body, {
      headers: fetchHeaders,
    });
  if (httpMethod === 'delete')
    return axios.delete(
      `${basePath}/${endpoint}`,
      { headers: fetchHeaders },
      {},
    );
  return null;
};
